import { ReactElement, useState, useEffect, useMemo } from 'react';
import { actions, RootState, ThunkDispatchType, AnyNote, ProfileNote } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { EVENT_TYPE, EXEC_CARD_TYPES } from '../../constants';
import { PAGE_URL } from '../../constants';
import { trackEventWithExtra } from '../../utils/appAnalyticsUtils';
import ProfileCategory from './ProfileCategory';
import { useMountEffect } from '../../utils/hooks';


type RouteParams = { leaderId: string; categoryId: string };


export const ProfileCategoryPage = (): ReactElement | null => {
  const navigate = useNavigate();
  const { categoryId: catIdStr, leaderId: leaderIdStr } = useParams<RouteParams>();

  const categoryId = catIdStr ? parseInt(catIdStr) : -1;

  const leaders = useSelector((root: RootState) => root.leaders);
  const profileNotes = useSelector((root: RootState) => root.profileNotes);
  const dispatch = useDispatch<ThunkDispatchType>();

  const leader = useMemo(() => {
    return leaders.leaders.find(l => l.id.toString() === leaderIdStr);
  }, [leaders.leaders, leaderIdStr]);

  const [showOnboarding, setShowOnboarding] = useState(false);

  const currentCategory = categoryId in profileNotes.categories ? profileNotes.categories[categoryId] : undefined;
  const currentNotes = (currentCategory?.notes || []).map(nId => profileNotes.notes[nId]).filter(n => !!n);

  const loaded = !!currentCategory && profileNotes.notesLoaded && profileNotes.categoriesLoaded;
  const showNotes = !!leader && loaded && currentNotes.length > 0;
  const hasEditPermission = !!leader?.permissions.categories[categoryId]?.edit;
  const pageTitle = categoryId in profileNotes.categories ? profileNotes.categories[categoryId].title : '';

  useEffect(() => {
    if (leaders.loaded && !leader) {
      navigate(PAGE_URL.DASHBOARD);
    }
  }, [leaders.loaded, leader, navigate]);

  useEffect((): void => {
    // Load profileNotes data if it's not loaded
    if (leader && leader.id > 0 && !profileNotes.categoriesLoaded) {
      dispatch(actions.profileNotes.fetchProfileCategories(leader.id));
    }
    if (leader && leader.id > 0 && !profileNotes.notesLoaded) {
      dispatch(actions.profileNotes.fetchProfileNotes(leader.id));
    }
  }, [profileNotes.categoriesLoaded, profileNotes.notesLoaded, leader, dispatch]);

  useMountEffect(() => {
    if (profileNotes.notesLoaded && Object.keys(profileNotes.notes).length < 1) {
      if (!leader?.is_shared) {
        setShowOnboarding(true);
      }
    }
  });

  const handleOnboardingClose = () => {
    setShowOnboarding(false);
  };

  const handleConfirmDelete = async (id: number) => {
    await dispatch(actions.profileNotes.deleteProfileNote(id));
    trackEventWithExtra({ eventName: EVENT_TYPE.DELETE_EXEC_CATEGORY_CARD, extra: {category: EXEC_CARD_TYPES.CUSTOM} });
  };

  const handleSaveNote = async (profileNote: AnyNote, addMode = false) => {
    const note = profileNote as ProfileNote;
    note.profile_category = categoryId;
    if (addMode && leader) {
      trackEventWithExtra({ 
        eventName: EVENT_TYPE.CREATE_EXEC_CATEGORY_CARD, 
        extra: {category: EXEC_CARD_TYPES.CUSTOM} 
      });
      await dispatch(actions.profileNotes.createProfileNote(note));
    } else {
      trackEventWithExtra({ 
        eventName: EVENT_TYPE.UPDATE_EXEC_CATEGORY_CARD, 
        extra: {category: EXEC_CARD_TYPES.CUSTOM} 
      });
      return dispatch(actions.profileNotes.updateProfileNote(note));
    }
  };

  const onBack = (): void => navigate(`${PAGE_URL.EXECUTIVE}/${leader?.id}`);

  if (!leader) return null;

  return (
    <ProfileCategory
      leader={leader}
      category={currentCategory}
      notes={currentNotes}
      showOnboarding={showOnboarding}
      showNotes={showNotes}
      loaded={loaded}
      hasEditPermission={hasEditPermission}
      pageTitle={pageTitle}
      onSaveNote={handleSaveNote}
      onDeleteProfileNote={handleConfirmDelete}
      onOnboardingClose={handleOnboardingClose}
      onBack={onBack}
    />
  );
};

export default ProfileCategoryPage;
